import { createContext, useState } from "react";
import { useEffect } from "react";

export const PagesContext = createContext(null)

export const PagesContextProvider = ({children})=>{

    const [Toggle, setToggle] = useState(null);

    function btn(){
        window.location.href = "https://forms.gle/g97nVeQWivfmhrza9"
    }

    const btn2 = ()=>{
        window.location.href = "https://forms.gle/AjQL2xothYCjMvRTA"
    }

    function btn_off(){
        setToggle(null)
    }

    const items = {
        btn,
        btn2,
        btn_off,
        Toggle
    }

   


    
    return(
        <PagesContext.Provider value={items}>{children}</PagesContext.Provider>
    )
}