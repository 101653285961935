import React from "react";
import "./form.css";
import { PagesContext } from '../../context';
import { useContext } from 'react';

export default function Form(){
    let {Toggle} = useContext(PagesContext)
    return(
        <div className={Toggle == "form" ? "form-container" : "d-none"}>
            <div className="form-layer">
                <div>
                    <h1>Ready to work with us?</h1>
                    <span>Send us a proposal today</span>
                </div>
                
                <a href="https://forms.gle/g97nVeQWivfmhrza9">Send a proposal</a>
            </div>
            <div className="form-image">
            </div>
        </div>
    )
}