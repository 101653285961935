import {React} from "react-dom"
import './App.css';
import Navbar  from "./component/Navbar/Navbar";
// import SignInPage from "./component/sign_in"
// import SignUpPage from "./component/sign_up"
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./component/Homepage";
import { PagesContextProvider } from "./context";
import Footer from "../src/component/footer"
import Form from "./component/form/form";
// import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';




function App() {
  return (
    <div className="App">
      <PagesContextProvider>
        <Navbar/>
        <Form/>
        <HomePage/>
        <Footer/>
      </PagesContextProvider>
    </div>
  );
}

export default App;
