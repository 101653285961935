import React from 'react';
import './navbar.css';
import { useContext } from 'react';
import { PagesContext } from '../../context';


export default function Navbar(){
    let {btn,btn2} = useContext(PagesContext);
    
    return(
        <nav className="nav">
            <div className="link">
                <div className="logo ">
                </div>
                <ul>
                    <li><a href="">Home</a></li>
                    <li><a href="">About Us</a></li>
                    <li><a href="">How it works</a></li>
                    <li><a href="">Success stories</a></li>
                    <li><a href="">FAQs</a></li>
                </ul>
            </div>
            <div className="button">
                <button onClick={()=>btn()}>Register</button>
                <button onClick={()=>btn2()}>Send Proposal</button>
            </div>
            <i className="bx bx-menu" id="menuBar"></i>
        </nav>
    )
}
